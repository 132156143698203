import React, {Component} from 'react';
import {connect} from "react-redux";
import Facade from "../Facade";
import "./HomePage.css";
import Utils from "../Utils";
import {WEB_URL} from "../consts";

import Swiper from "react-id-swiper";
import 'react-id-swiper/src/styles/css/swiper.css'
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import TimeUtil from "../TimeUtil";
import TopBar from "../components/TopBar";

class HomePage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {task: [], typeNum: {}};
  }

  componentDidMount()
  {
    this.refreshData();
  }

  refreshData()
  {
    this.refreshBannerList();
    Facade.gw.getNoticeList( 1,3,(data)=>{
      this.setState({noticeList:data});
    });
    Facade.gw.getHomeMenuList(8,(data)=>{
      this.setState({menuList:data});
    });
    Facade.gw.getActivityList("",1,20,(data)=>{
      this.setState({huodongList:data});
    });


  }

  render()
  {
    let {} = this.state;
    return (
      <div className="HomePage ">

        <TopBar activeIndex={0}/>

        <div className="pcrow">
          <div className="banner">

            {this.state.bannerList && <Swiper
              ref={(swiper) => {
                this.swiper = swiper;
              }}
              autoplay={{delay: 2000}}
              loop={true}
              //
              //
              //             // spaceBetween={0}
              //             slidesPerView={"auto"}
              //             slidesOffsetBefore={0}
              //             // slidesOffsetAfter={0}
              //             centeredSlides={true}
              //             shouldSwiperUpdate
              // speed={300}
              pagination={{el: '.swiper-pagination', clickable: true,  bulletActiveClass: 'my-bullet-active', bulletClass: 'my-bullet',}}
              // renderPagination={this.renderPagination.bind(this)}
            >
              {this.state.bannerList.map((item, index) => {
                let {pic_url, video, link} = item;
                if (pic_url)
                {
                  return (
                    // <img key={"p"+index} src={pic_url} alt=""/>
                    <a href={link} key={"p"+index}><img src={pic_url} alt=""/></a>
                  )
                } else if (video)
                {
                  return (
                    <a href={link} key={"v"+index}>
                    <video src={video} autoPlay={true} playsInline={true} muted={true} controls={false} loop={true}/>
                    </a>
                  );
                }
              })}
            </Swiper>}
          </div>

          <div className="pccol">

            <div className="gonggao mobile-only">
              <img className={"gonggao-ico"} src={require("../res/0306/首页/home_gonggao.png")} alt=""/>
              <div className="gonggao-tf">园区公告:</div>
              {this.renderNoticeListMob()}
            </div>

            <div className="gonggao pc-only">
              <img className={"gonggao-ico"} src={require("../res/pc/pc_notification.png")} alt=""/>
              <div className="gonggao-tf">园区公告:</div>
            </div>
            {this.renderNoticeListPc()}


            <div onClick={() => {
              window.location.href = "https://iuxlabs.feishu.cn/share/base/form/shrcnCobdkA2jLscb26zaD32ure"
            }} className="qyrz">
              <div className={"qyrz-title"}>
                <img className={"qyrz-ico"} src={require("../res/0306/首页/home_renzheng.png")} alt=""/>
                企业认证</div>
              <div className={"qyrz-tf"}>完成会员认证，享受更全面的服务</div>
              <div className={"qurenzheng"}>去认证</div>
            </div>

          </div>
        </div>

        <div className="card-head mobile-only">
          <div className="card-title mobile-only">
            园区服务
          </div>
          <Link to={"services"} className="card-rightbtn mobile-only">
            更多服务 <img src={require("../res/0306/首页/home_more.png")} alt=""/>
          </Link>
        </div>
        <div className="yqfw mobile-only">
          {
            this.state.menuList && this.state.menuList.map(this.renderFuWu.bind(this))
          }

        </div>


        <div className="card-head">
          <div className="card-title">
            <img src={require("../res/pc/pc_information.png")} alt="" className={"pc-only"}/>
            活动信息
          </div>
          <Link to={"explore"} className="card-rightbtn">
            更多活动 <img src={require("../res/0306/首页/home_more.png")} alt=""/>
          </Link>
        </div>

        <div className="huodong">
          {this.state.huodongList && this.state.huodongList.map(this.renderHuodong.bind(this))}

        </div>

        <Footer activeIndex={0} />

      </div>);
  }

  renderPagination (index, total, context)  {
  return (


    <div>{index + 1}/{total}</div>


)
}

  renderNoticeListMob()
  {
    let {noticeList} = this.state;
    if (!noticeList || noticeList.length === 0) return (null);
    return (
            <Swiper

              direction={"vertical"}
              autoplay={{delay: 2000}}
              loop={true}
              wrapperClass={"gonggao-list"}
            >
              {noticeList.map(this.renderNotice.bind(this))}
            </Swiper>
    )
  }
  renderNoticeListPc()
  {
    let {noticeList} = this.state;
    if (!noticeList || noticeList.length === 0) return (null);
    return (

      <div className={"gonggao-list-pc pc-only"}>
        {noticeList.map(this.renderNotice.bind(this))}
      </div>

    )
  }

  renderNotice(vo){
    return(<a href={vo.link} key={"notice" + vo.id} className={"gonggao-li"}>
      {vo.title}</a>);
  }

  renderFuWu(vo)
  {
    let {name,icon,link} = vo;
    return (
      <a href={link} className="fuwu-li" key={"fuwu"+vo.id}>
        <img src={icon} alt=""/>
        <div className="fuwu-name">{name}</div>
      </a>
    )
  }

  renderHuodong(vo,index)
  {
    let {title,time_start,time_end,type,link,pic_url}= vo;
    let actDate = TimeUtil.formatDateOrTime(time_start);//+"~"+TimeUtil.formatDateOrTime(time_end);

    return (
      <a className={"huodong-li "+(index>3?'pc-only':'')} key={"huodong"+vo.id} href={link}>
        <img className={"huodong-pic"} src={pic_url} alt=""/>
        <div className="huodong-name">{title}</div>
        <div className="huodong-info">
          <div className="huodong-date">{actDate}</div>
          <div className="huodong-address">{type==1?"线上":"线下"}</div>
        </div>
      </a>
    )
  }

  refreshBannerList()
  {
    Facade.gw.getBannerList((data) => {
      if (data.length>4) data.length=4;
      this.setState({bannerList: data});
    })
  }
}

export default HomePage;

// const mapStateToProps = (state) => {
//   // console.log("loginPage/mapStateToProps",state.customers1.customerList);
//   return {customerList: state.customers.customerList};
// };
// // export default LoginPage;
// export default connect(
//   mapStateToProps
// )(HomePage);