// 跟进目的

export const PURPOSES = [
  {value: 0, label: '等待服务'},
  {value: 1, label: '客户预约'},
  {value: 2, label: '售前'},
  {value: 3, label: '客户成功'},

];
// 客户状态
export const CUSTOMER_LABELS=[
  {value: 0, label: '新客户', show:false, selectable:false},
  {value: 1, label: '未联系上', show:true, selectable:true},
  {value: 2, label: '需转化', show:true, selectable:true},
  {value: 3, label: '已转化', show:true, selectable:false},
  {value: 4, label: '无效客户',show:true, selectable:true}
];

export const STATS_DIRECTION_UP = 'up';
export const STATS_DIRECTION_DOWN = 'down';
export const STATS_VIEW_MONTH= 'month';
export const STATS_VIEW_WEEK= 'week';
export const STATS_VIEW_ALL= 'all';

export const WORK_DATE_NATURE = 'nature';


//taskType：必传，4节点负责人，5任务负责人，6项管/设计院/QS责任人，7进度描述，8完成时间，9计划/实际完成时间，10备注，11修改任务名
export const EDIT_TASK_TYPE_managerUserList = 4;
export const EDIT_TASK_TYPE_taskUserList = 5;
export const EDIT_TASK_TYPE_DEPARTMENT = 6;
export const EDIT_TASK_TYPE_description = 7;
export const EDIT_TASK_TYPE_taskFinishDate = 8;
export const EDIT_TASK_TYPE_planRealDateFinish = 9;
export const EDIT_TASK_TYPE_remark = 10;
export const EDIT_TASK_TYPE_taskName = 1;
export const ROOT_TASK_ID = 1;


//export const SERVER_API="http://49.234.66.116:9000"; //<-iUX 备份服
// export const SERVER_API="https://sciencepark.iuxlabs.com/api"; //<-iUX 最新服
export const SERVER_API="/api"; //<-iUX 最新服 相对路径调用 对应package.json里的proxy 可代理跨域
// export const SERVER_API="http://hpvrfm.natappfree.cc/api"; //<-闽泉本地

export const IS_LOCALHOST=window.document.location.href.indexOf("http://localhost")==0 || window.document.location.href.indexOf("http://127.0.0.1")==0;
export const IS_LAN=(window.document.location.href.indexOf("http://192.168.")==0 );
export const IS_ENV_DEV=IS_LOCALHOST||IS_LAN;

// export const DEBUG = IS_ENV_DEV;
// export const DEBUG = false;//<-iux
export const DEBUG = true;

// 登录跳转回的url
export const CLIENT_WWW_PRODUCTION="https://sciencepark.iuxlabs.com";//<-iUX
export const CLIENT_WWW_DEV="http://192.168.50.4:3000";//<-本地测试
//export const CLIENT_WWW="http://www.iuxlabs.com/bdbim/test";//<-iUX test
// export const CLIENT_WWW=CLIENT_WWW_PRODUCTION;

export const CLIENT_WWW = IS_ENV_DEV?CLIENT_WWW_DEV:CLIENT_WWW_PRODUCTION;

//分享网站url
export const WEB_URL = CLIENT_WWW;

/** 飞书应用的appid */
// export const LARK_H5_APPID ="cli_9faba44577b0100c";//crane dance
export const LARK_H5_APPID ="cli_a3c4af93e7f81013";//djbdc 测试租户
// export const LARK_H5_APPID ="cli_a279ea6a917a500b";//djbdc iUXLabs

/**************** 周报任务权限 ******************/
//region 周报任务权限
export const TASK_EDIT_PERMISSION_PROJECT_OWNER=3;
export const TASK_EDIT_PERMISSION_MANAGE_USER=2;
export const TASK_EDIT_PERMISSION_TASK_USER=1;
//endregion

export const VERSION ="230214";