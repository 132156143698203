import Gateway from "./Gateway";
import Notify from "./Notify";
import ScreenHelper from "./ScreenHelper";
import OAuthLoginUtil from "./OAuthLoginUtil";
import LarkSdkUtil from "./LarkSdkUtil";
import PageBottomChecker from "./PageBottomChecker";

export default class Facade{

  static customHistory =null;

  /**
   * 监听map
   * [notifyName][thisObj][handlerMethod]
   * 这种结构的好处是可以提供 对一个obj的批量移除监听的方法
   */
  static listenersMap  = new Map();

  /**
   * 发送通告
   * @param data 数据
   * @param name 通告类型名
   */
  static sendNotify(name, sender, data)
  {
    var notify = new Notify(name, sender, data);
    if (Facade.listenersMap.has(name))
    {
      let notifyListenerMap = Facade.listenersMap.get(name);
      notifyListenerMap.forEach((handlers, thisObj) => {
        handlers.forEach((handler) => {
          handler.call(thisObj, notify);
        });
      });
    }
  }

  /**
   * 注册对通告的监听.
   * 当触发时将回调handler(Notify)
   * 只支持一个thisObj对应一个handlerMethod，不可以注册多重thisObj
   */
  static regListener(notifyName, handler, thisObj)
  {
    if (!Facade.listenersMap.has(notifyName))
    {
      Facade.listenersMap.set(notifyName, new Map());
    }
    let notifyListenerMap = Facade.listenersMap.get(notifyName);
    if (!notifyListenerMap.has(thisObj))
    {
      notifyListenerMap.set(thisObj, []);
    }
    let handlerMethods = notifyListenerMap.get(thisObj);
    if (handlerMethods.indexOf(handlerMethods) == -1)
      handlerMethods.push(handler);


  }

  /**
   * 移除监听.
   * @param notifyName
   * @param handler
   */
  static removeListener(notifyName, thisObj)
  {

    if (Facade.listenersMap.has(notifyName))
    {
      let notifyListenerMap = Facade.listenersMap.get(notifyName);
      if (notifyListenerMap.has(thisObj))
      {
        notifyListenerMap.delete(thisObj);
      }
    }

  }




  static gw = new Gateway();
  static screenHelper = new ScreenHelper();
  static oauth=new OAuthLoginUtil();
  static pageBottomChecker = new PageBottomChecker();

  static larkSdkUtil = new LarkSdkUtil();


}