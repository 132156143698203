import Notify from "./Notify";
import Facade from "./Facade";
import {CLIENT_WWW,DEBUG,SERVER_API} from "./consts";

/**
 * Created by rhett on 2019/9/23.
 */
// const SERVER_API = "/api";
// const SERVER_API = "";



export default class Gateway
{

  //region 基本请求
  token = "";

  getQueryVariable(variable)
  {
    var query = window.location.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++)
    {
      var pair = vars[i].split("=");
      if (pair[0] == variable)
      {
        return pair[1];
      }
    }
    return (false);
  }


  //
  gwAlert = console.debug;
  gwToast = console.debug;
  needloginCb = () => {
    this.gwToast('正在从飞书同步登录状态...', '未登录');
    Facade.oauth.gotoOAuth()
  };

  gwSimplePost(api, extraArg, cb, errCb)
  {
    let formData = new FormData();
    for (let eachKey in extraArg)
    {
      formData.append(eachKey, extraArg[eachKey]);
    }

    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    if (DEBUG) console.log("POST", api, extraArg);
    fetch(SERVER_API + api, {headers, method: "post", credentials: "include", body: formData})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if (DEBUG) console.log(">POST", api, resp);
        if (resp.error == 0)
        {
          cb(resp.data);
        } else if (resp.error == 20003)
        {
          this.needloginCb();
        } else
        {
          let isSwallow = false;
          if (errCb != null)
            isSwallow = errCb(resp);
          if (!isSwallow) this.gwAlert(resp.error + ":" + resp.message, resp.error == 20001 ? api : null);
        }

      }.bind(this)).catch((error) => {
      this.gwAlert("服务器错误" + error.message, api);
    });

  }

  gwSimpleGet(api, extraArg, cb, errCb)
  {
    let url = SERVER_API + api;
    if (extraArg)
    {
      let args = [];
      for (let eachKey in extraArg)
      {
        args.push(eachKey + "=" + extraArg[eachKey]);
      }
      if (args.length)
        url = url + "?" + args.join("&");
    }
    let headers = {rest: "rest"};
    if (this.token) headers["token"] = this.token;
    if (DEBUG) console.log("GET", api, extraArg, this.token);
    fetch(url, {headers, method: "get", credentials: "include"})
      .then(function (response) {
        return response.json();
      })
      .then(function (resp) {
        if (DEBUG) console.log(">GET", api, resp);
        if (resp.error == 0)
        {
          cb(resp.data);
        } else if (resp.error == 20003)
        {
          this.needloginCb();
        } else
        {
          let isSwallow = false;
          if (errCb != null)
            isSwallow = errCb(resp);
          if (!isSwallow) this.gwAlert(resp.error + ":" + resp.message, resp.error == 20001 ? api : null);
        }

      }.bind(this)).catch((error) => {
      this.gwAlert("服务器错误" + error.message, api);
    })
  }

  //endregion

  /**************** 上传COS ******************/

  //region 上传COS
  /**
   * 获取临时秘钥
   * get：/cos/getCredential
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": {
   *         "credentials": {
   *             "tempSecretId": "AKIDO_iV8Xj4Dx2M657KgKU4D5Tnv-bk01HCzJMH7ot1PqdT8mDR79xKQng5eUQCR_W5",
   *             "tmpSecretKey": "XFK4c8u4q/UXZ44Db1xkBObNTbYvkHMLnGlep+nsakI=",
   *             "sessionToken": "txL120yrUSZDXujN1hyHmM33FtPZJkCa4e79ecee48a2a75e0c16594c69768353oE0cSeRhCp0OZp71O9SqbMOXzXlN0y8Mpkzp0TzqUxgxMV_FNbO2uKFSr3CS7UfC3kSG1Jqw_G_U8j2DQutf4mSCRKPi742Em8P1q7Wqqu_nMkNiCgGGf4YFrupxZgT4c_Pg3Hr0ZXlyYTzo_3deO_miyhPp2ma1cmjPmC4kdruOuGgWVyjLuzmSkZGZm7URcbOMrsT1zvRh8XY0lP8S8GGQd2H8yZ1P1co0QhVQwEGArDw2e26HmD-pSOIk6FLTbOcW1WmKMgBQp0-Wnq52L0NGbprZOjBXoP9FX-0Za8yeefbKhwqo17a7tvBVeCEbc02axebZu_y86kL6G6RKhosX0-31j5Yu0UpAx15nPfNEGH42oY13vzn9pzgYTnAwsskDnGHXEqrkfABKk0M2nErmrKqiP01AIY7AuWGPCankYbZpDAPbD18FstRHe9wc7TMud0Bf3WYp-c6autYyoiTexCy8WtmUrCIskkT3AOGc_hgvqqjqtVeIX3_FtK6cND25wiyvHX7MFVpMFLgbDSia-Cdies5V_Af7Ywdxpz-AhhgARoTPaUMVdx45PRa5"
   *         },
   *         "startTime": "2022-11-29 09:35:40",
   *         "expiredTime": "2022-11-29 10:05:40"
   *     }
   * }
   */
  getStsAuth(cb)
  {
    this.gwSimpleGet("/cos/getCredential", {}, cb);
  }

  //endregion

  /**************** banner列表 ******************/

  //region banner

  /**
   * banner列表
   * get：/banner/finds
   *
   * 返回：
   * {
   *    "error": 0,
   *    "message": "success",
   *    "data": [
   *       {
   *          "id": "1",
   *          "pic_url": "https://fitsfun-1251248672.file.myqcloud.com/assets/16091383885314Z2TS6.jpg",   //图片url
   *          "video": null,   //视频url
   *          "link": "https://xxx",   //跳转链接
   *          "sort": "0",   //排序，数字越大排序越靠前
   *          "status": "0",   //0上架，1下架
   *          "create_time": "2023-03-01 15:39:50"
   *       }
   *    ]
   * }
   */
  getBannerList(cb)
  {
    this.gwSimpleGet("/banner/finds", {}, cb);
  }

  //endregion


  /**************** 活动 ******************/

  //region 活动
  /**
   * 活动列表
   * get：/activity/finds
   * 参数：
   * - page：默认1
   * - limit：默认10
   * 返回：
   * {
   *    "error": 0,
   *    "message": "success",
   *    "data": [
   *       {
   *                "id": "1",
   *          "title": "活动标题",   //标题
   *       "pic_url": "https://xxx",   //活动头图
   *          "time_start": "2023-03-15 13:00:00",   //开始时间
   *          "time_end": "2023-03-15 15:00:00",   //结束时间
   *          "type": "1",   //1线上2线下
   *          "link": "https://xxx",   //跳转链接
   *          "create_time": "2023-03-13 10:00:56"
   *       }
   *    ]
   * }
   */
  getActivityList(search,page, limit, cb)
  {
    this.gwSimpleGet("/activity/finds", {search,page, limit}, cb);
  }

  //endregion

  /**
   * 公告列表
   * get：/notice/finds
   * 参数：
   * - page：默认1
   * - limit：默认10
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": "1",
   *             "title": "1",   //标题
   *             "link": "1",   //跳转链接
   *             "status": "0",   //0正常，1下架
   *             "create_time": "2023-02-27 16:36:33"
   *         }
   *     ]
   * }
   */
  getNoticeList(page, limit, cb)
  {
    this.gwSimpleGet("/notice/finds", {page, limit}, cb);
  }

  /**
   * 功能列表
   * get：/menu/finds
   * 参数：
   * - isShow: 可选，获取首页功能列表时传1，获取全部时传0或者不传
   * - page：默认1
   * - limit：默认10
   * 返回：
   * {
   *    "error": 0,
   *    "message": "success",
   *    "data": [
   *       {
   *          "id": "1",
   *          "name": "企业注册",   //名称
   *          "icon": null,    //icon图片url
   *          "link": "https://xxx",   //跳转链接
   *    "menu_type_name": "基础服务",   //分组
   *          "sort": "0"   //排序，数字越大，排序越靠前
   *       }
   *    ]
   * }
   */
  getMenuList(page, limit, cb)
  {
    this.gwSimpleGet("/menu/finds", {page, limit}, cb);
  }
  getHomeMenuList(limit,  cb)
  {
    this.gwSimpleGet("/menu/finds", {isShow:1,page:1, limit}, cb);
  }

  /**
   * 招聘列表
   * get：/recruitment/finds
   * 参数：
   * - search：搜索内容
   * - page：默认1
   * - limit：默认10
   * 返回：
   * {
   *     "error": 0,
   *     "message": "success",
   *     "data": [
   *         {
   *             "id": "1",
   *             "position": "秘书",   //招聘职位
   *             "company": "xx企业",   招聘企业
   *             "type": "1",   //1全职，2兼职
   *             "education": "本科",   //学历要求
   *             "population": "1",   //招聘人数
   *             "workplace": "上海市杨浦区",   //工作地点
   *             "salary": "0",   //薪资，0为面议，其他数值为具体工资数额
   *             "status": "0",   //0正常，1下架
   *             "create_time": "2023-03-13 12:02:11"
   *         }
   *     ]
   * }
   */
  getRecruitmentList(search , page, limit, cb)
  {
    this.gwSimpleGet("/recruitment/finds", {search,page, limit}, cb);
  }

}