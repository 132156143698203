import React, {Component} from 'react';
import "./ExplorePage.css";
import 'react-id-swiper/src/styles/css/swiper.css'
import Footer from "../components/Footer";
import TabBar from "../components/TabBar";
import SearchBar from "../components/SearchBar";
import Facade from "../Facade";
import Notify from "../Notify";
import MZhaoPin from "../MZhaoPin";
import MHuoDong from "../MHuoDong";
import TimeUtil from "../TimeUtil";
import TopBar from "../components/TopBar";

class ExplorePage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {task: [], typeNum: {}};
  }

  model = null;
  modelZhaoPin = new MZhaoPin();
  modelHuoDong = new MHuoDong();

  componentDidMount()
  {
    let activeIndex = this.props.match.params.activeIndex;
    if (!activeIndex) activeIndex = 0;
    this.changeModel(activeIndex);

    Facade.regListener(Notify.PAGE_REACH_BOTTOM, (n) => {
      this.model.loadMore();
    });
    Facade.regListener(Notify.HUODONG_LIST_CHANGED, this.onListChanged.bind(this));
    Facade.regListener(Notify.ZHAOPIN_LIST_CHANGED, this.onListChanged.bind(this));

  }

  onListChanged(n)
  {
    if (n.target == this.model)
    {
      // 真实数据
      this.setState({listItems: this.model.listItems});

      // // 模拟数据来10倍叠加
      // let listItems=this.model.listItems;
      // for (let i = 0; i < 5; i++)
      // {
      //   listItems=listItems.concat(listItems);
      // }
      // this.setState({listItems});
      // //模拟数据结束

      Facade.pageBottomChecker.pollScroll();
    }
  }

  changeModel(activeIndex)
  {
    this.model = (activeIndex == 0) ? this.modelHuoDong : this.modelZhaoPin;
    this.model.clear(this.lastKeyword);
    this.model.loadMore();
  }

  componentWillUnmount()
  {
    Facade.removeListener(Notify.PAGE_REACH_BOTTOM, this);
    Facade.removeListener(Notify.HUODONG_LIST_CHANGED, this);
    Facade.removeListener(Notify.ZHAOPIN_LIST_CHANGED, this);
  }

  lastKeyword = "";

  validateKeywords(keyword)
  {
    this.lastKeyword = keyword;
    this.model.clear(keyword);
    this.model.loadMore();
  }

  componentWillReceiveProps(nextProps, nextContext)
  {
    if (nextProps.match.params.activeIndex != this.props.match.params.activeIndex)
    {
      this.changeModel(nextProps.match.params.activeIndex);
    }
  }

  render()
  {
    let {} = this.state;
    let activeIndex = this.props.match.params.activeIndex;
    if (!activeIndex) activeIndex = 0;
    return (
      <div className="ExplorePage ">
        <TopBar activeIndex={1}/>

        <div className="pcrow-reverse">

        <SearchBar onSearch={this.validateKeywords.bind(this)}/>

        <TabBar activeIndex={activeIndex}/>
        </div>

        {activeIndex == 0 ? this.renderHuodongList() : this.renderZhaoPinList()}

        <Footer activeIndex={1}/>

      </div>);
  }

  renderZhaoPinList()
  {
    if ((this.state.listItems && this.state.listItems.length>0)){
      return (<div className="zhaopin-list">
        {this.state.listItems.map(this.renderZhaopin.bind(this))}
      </div>);
    }else{
      return (<div className="no-data">
        <img src={require("../res/0322/empty.png")} alt=""/>
        很抱歉, 暂时没有内容</div>)
    }


  }

  renderZhaopin(vo)
  {
    let {position,link ,company, type,education ,population,workplace,salary}=vo;
    return (
      <a href={link} className="zp">
        <div className="zp-content">
          <div className="zp-row">
            <div className="zp-title">{position}</div>
            <div className="zp-price">{salary}</div>
          </div>
          <div className="zp-company">{company}</div>
          <div className="zp-tags">
            <div className="zp-tag">{type==1?'全职':'兼职'}</div>
            <div className="zp-tag">{education}</div>
            <div className="zp-tag">{population}人</div>
          </div>
          <div className="zp-row">
            {/*<div className="zp-hr">*/}
            {/*  <img src={require("../res/0306/首页/home_zhongchuangkongjian.png")} alt="" className="zp-hr-ico"/>*/}
            {/*  <div className="zp-hr-name">匿名·HR</div>*/}
            {/*</div>*/}
            <div className="zp-address">{workplace}</div>
          </div>
        </div>
      </a>
    )
  }

  renderHuodongList()
  {
    if ((this.state.listItems && this.state.listItems.length>0)){
      return (<div className="huodong-list">
        {this.state.listItems.map(this.renderHuodong.bind(this))}
      </div>);
    }
    else{
      return (<div className="no-data">
        <img src={require("../res/0322/empty.png")} alt="" className={"mobile-only"}/>
        <img src={require("../res/0322/pc_empty.png")} alt="" className={"pc-only"}/>
        很抱歉, 暂时没有内容</div>);
    }
  }

  renderHuodong(vo)
  {
    let {title,link, time_start,time_end, type, pic_url} = vo;
    let actDate = TimeUtil.formatDateOrTime(time_start);
    let actDatePart2 = "~"+TimeUtil.formatDateOrTime(time_end);
    return (
      <a href={link} className="explore-huodong-li">
        <img className={"explore-huodong-pic"} src={pic_url}
             alt=""/>
        <div className="explore-huodong-right">
          <div className="explore-huodong-name">{title}</div>
          <div className="pcrow">
            <div className="explore-huodong-date">{actDate}<span className={"mobile-only"}>{actDatePart2}</span></div>
            <div className="explore-huodong-address">{type==1?"线上":"线下"}</div>
          </div>
        </div>
      </a>
    )
  }


}

export default ExplorePage;