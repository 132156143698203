import React, {Component} from 'react';
import "./Footer.css";
import {Link} from "react-router-dom";

export default class Footer extends Component
{

  componentDidMount()
  {
  }

  componentWillReceiveProps(nextProps, nextContext)
  {

  }

  render()
  {
    let {activeIndex} = this.props;
    return (
      <div>
        <div className="footer-before mobile-only">
          {this.renderVer()}
        </div>
        <div className="footer mobile-only">
          <Link className={"footer-tabli " + (activeIndex == 0 ? 'active' : '')} replace={true} to={"home"}>
            <img className={"footer-tabicon"} src={
              activeIndex == 0 ?
                require("../res/0306/首页/home1.png") :
                require("../res/0306/首页/home2.png")
            } alt=""/>
            <div className="footer-tabli-name">首页</div>
          </Link>
          <Link className={"footer-tabli " + (activeIndex == 2 ? 'active' : '')} replace={true} to={"services"}>
            <img className={"footer-tabicon"} src={activeIndex == 2 ?
              require("../res/0322/service1.png") :
              require("../res/0322/service2.png")
            } alt=""/>
            <div className="footer-tabli-name">服务</div>
          </Link>
          <Link className={"footer-tabli " + (activeIndex == 1 ? 'active' : '')} replace={true} to={"explore"}>
            <img className={"footer-tabicon"} src={activeIndex == 1 ?
              require("../res/0306/首页/discover1.png") :
              require("../res/0306/首页/discover2.png")
            } alt=""/>
            <div className="footer-tabli-name">发现</div>
          </Link>
        </div>

        <div className="footer pc-only">
          {this.renderVer()}
        </div>
      </div>);
  }

  renderVer(){
    return (
      <div className="footer-ver">设计开发 by <a href={"https://iuxlabs.com"}>iUXLabs</a> 版本:1.2304.12</div>
    );
  }

}