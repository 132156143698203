/**
 * Created by rhett on 2018/6/11.
 */
export default class ScreenHelper
{


  _appInstance = null;


  /** 获得窗口高度 */
  getWinH()
  {
    return window.innerHeight;
  }

  init(appInstance)
  {
    this._appInstance = appInstance;

    this._onResizeHandle = this.onResizeHandle.bind(this);
    window.addEventListener("resize", this._onResizeHandle);
    this.onResizeHandle();

  }

  onResizeHandle()
  {
    console.log("App/onResizeHandle", this.getWinH());
    let winH = this.getWinH()+"px";
    let eles = document.getElementsByClassName("hSync");
    for(let i = 0;i<eles.length;i++)
    {
      eles[i].style.height = winH;
    }
    eles = document.getElementsByClassName("minHSync");
    for(let i = 0;i<eles.length;i++)
    {
      eles[i].style.minHeight = winH;
    }
  }


  dispose()
  {
    window.removeEventListener("resize", this._onResizeHandle);
  }
}