import Facade from "./Facade";
import Notify from "./Notify";

export default class PageBottomChecker {


  constructor(){

  }


   checkScroll(){
    if(!this.lowEnough()) return this.pollScroll();
    Facade.sendNotify(Notify.PAGE_REACH_BOTTOM,this,null);

  }

  /**
   * 每次调用，将会检测到触发一次为止
   */
  pollScroll(){
    setTimeout(this.checkScroll.bind(this),1000);
  }
   lowEnough(){
    var pageHeight = Math.max(document.body.scrollHeight,document.body.offsetHeight);
    var viewportHeight = window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight || 0;
    var scrollHeight = window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop || 0;
    // console.log(pageHeight);
    // console.log(viewportHeight);
    // console.log(scrollHeight);
    return pageHeight - viewportHeight - scrollHeight < 20;
  }
}
