import React, {Component} from 'react';
import "./TabBar.css";
import {Link} from "react-router-dom";

export default class TabBar extends Component
{

  componentDidMount()
  {
  }

  componentWillReceiveProps(nextProps, nextContext)
  {

  }

  render()
  {
    let {activeIndex} = this.props;
    return (
      <div className="tabbar">
        <Link to={"explore-0"} className={"tabli "+(activeIndex==0?'active':'')}>活动</Link>
        <Link to={"explore-1"} className={"tabli "+(activeIndex==1?'active':'')}>招聘</Link>
      </div>
    );
  }

}