import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Route, Router, Switch} from "react-router-dom";
import {createBrowserHistory} from 'history';
import configureStore from "./stores";
import {Provider} from "react-redux";
import {Notification} from "element-react";
import Facade from "./Facade";
import HomePage from "./pages/HomePage";
import ExplorePage from "./pages/ExplorePage";
import ServicesPage from "./pages/ServicesPage";

require("font-awesome/css/font-awesome.css");

const ROUTE_ROOTPATH = "(/stkjy)?/";  // 本地是根目录，兼容服务器上带前缀目录

const store = configureStore();
// const customHistory = createHashHistory();
const customHistory = createBrowserHistory();
Facade.customHistory = customHistory;
const Reducer = () => {
  ReactDOM.render(
    // <Provider store={store}>
      <Router history={customHistory}>

        <Switch>
          <Route path={ROUTE_ROOTPATH + "services"} component={ServicesPage}/>
          <Route path={ROUTE_ROOTPATH + "explore"} component={ExplorePage}/>
          <Route path={ROUTE_ROOTPATH + "explore-:activeIndex"} component={ExplorePage}/>
          <Route path={ROUTE_ROOTPATH + "home"} component={HomePage}/>
          <Route path={ROUTE_ROOTPATH} component={HomePage}/>
        </Switch>

      </Router>
    // </Provider>
    , document.getElementById('root'));
};

Reducer();

Facade.gw.gwAlert =(message,title)=>{
  Notification.error({
    title: title || "提示",
    message,
    duration: 0
  });
};
Facade.gw.gwToast=(message,title)=>{
  // Message.warning ({message:(title?title :"") +" "+message ,customClass:"topWarning"});

  Notification.info({
    title: title || "提示",
    message,
    duration: 0
  });
};


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
