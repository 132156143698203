import React, {Component} from 'react';
import "./TopBar.css";
import {Link} from "react-router-dom";
import Facade from "../Facade";

export default class TopBar extends Component
{

  constructor(props)
  {
    super(props)
    this.state = {};
  }

  componentDidMount()
  {
    Facade.gw.getHomeMenuList(8, (data) => {
      this.setState({homeMenuList: data});
    });
    Facade.gw.getMenuList(1, 99, (data) => {
      let menuGroupDic = {};
      // 根据 data 中的 type 字段，将 data 分组
      for (let i = 0; i < data.length; i++)
      {
        let item = data[i];
        let type = item.menu_type_name;
        if (!menuGroupDic[type])
        {
          menuGroupDic[type] = [];
        }
        menuGroupDic[type].push(item);
      }
      this.setState({menuGroupDic});
    });

  }

  onHoverService()
  {
    clearTimeout(this.outServiceTimer);
    this.setState({showAllMenu: true});
  }

  onOutService()
  {
    clearTimeout(this.outServiceTimer);
    this.outServiceTimer = setTimeout(() => {
      this.setState({showAllMenu: false});
    }, 400);
  }

  render()
  {
    let {activeIndex} = this.props;
    return (
      <div className={"topbar pc-only"}>

        <div className="topbar-row">
          <div className="logotitle">
            <img className="logo" src={require("../res/0322/pc_logo.png")} alt="上海体育国家大学科技园"/>
          </div>
          <div className="toptab">
            <Link className={"toptab-tabli " + (activeIndex == 0 ? 'active' : '')}  to={"home"}>
              <div className="toptab-tabli-name">首页</div>
            </Link>
            <Link className={"toptab-tabli " + (activeIndex == 2 ? 'active' : '')}  to={"services"}>
              <div className="toptab-tabli-name">服务</div>
            </Link>
            <Link className={"toptab-tabli " + (activeIndex == 1 ? 'active' : '')}  to={"explore"}>
              <div className="toptab-tabli-name">发现</div>
            </Link>
          </div>
        </div>

        <div className="topmenu">
          {this.renderHomeMenu()}

          <Link className="topmenu-more" to={"services"} onMouseOver={this.onHoverService.bind(this)}
             onMouseOut={this.onOutService.bind(this)}>
            更多服务
            <img className="topmenu-more-icon" src={require("../res/pc/pc_more.png")} alt=""/>
            <img className="topmenu-more-icon active" src={require("../res/pc/pc_more2.png")} alt=""/>
          </Link>
        </div>

        <div className={"topmenu-all "+(this.state.showAllMenu?"":" hidden")} onMouseOver={this.onHoverService.bind(this)}
             onMouseOut={this.onOutService.bind(this)}>
          {this.renderMenu()}
        </div>


      </div>);
  }

  renderHomeMenu()
  {
    if (this.state.homeMenuList)
      return this.state.homeMenuList.map(this.renderFuWu.bind(this));
  }

  renderFuWu(vo)
  {
    let {name, icon, link} = vo;
    return (
      <a href={link} className="fuwu-li" key={"fuwu" + vo.id}>
        <img src={icon} alt=""/>
        <div className="fuwu-name">{name}</div>
      </a>
    )
  }

  renderMenu()
  {

    let {menuGroupDic} = this.state;
    //为menuGroupDic 每一组输出一个card-head 然后输出每一组的 fuwu项

    var domList = [];
    menuGroupDic && Object.keys(menuGroupDic).forEach((key) => {
      let group = menuGroupDic[key];
      domList.push(<div className="fuwu-grouptitle" key={"head-" + key}>
        {key}
      </div>);
      domList.push(
        <div className="fuwu-group" key={"fuwu-" + key}>
          {group.map((vo) => {
            return this.renderFuWu(vo);
          })}
        </div>);
    });

    return domList;
  }

}