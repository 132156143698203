import React, {Component} from 'react';
import "./ServicesPage.css";
import Footer from "../components/Footer";
import {Link} from "react-router-dom";
import Facade from "../Facade";
import TopBar from "../components/TopBar";

class ServicesPage extends Component
{
  constructor(props)
  {
    super(props)
    this.state = {task: [], typeNum: {}};
  }

  componentDidMount()
  {
    this.refreshData();
  }

  refreshData()
  {
    Facade.gw.getMenuList(1,99,(data) => {
      let bannerGroupDic = {};
      // 根据 data 中的 menu_type_name 字段，将 data 分组
      for (let i = 0; i < data.length; i++)
      {
        let item = data[i];
        let type = item.menu_type_name;
        if (!bannerGroupDic[type])
        {
          bannerGroupDic[type] = [];
        }
        bannerGroupDic[type].push(item);
      }
      console.log("ServicesPage/",bannerGroupDic);
      this.setState({bannerGroupDic});
    })

  }

  render()
  {
    let {bannerGroupDic} = this.state;
    //为bannerGroupDic 每一组输出一个card-head 然后输出每一组的 fuwu项

    var domList =[];
    bannerGroupDic && Object.keys(bannerGroupDic).forEach((key)=>{
      let group = bannerGroupDic[key];
      domList.push (<div className="card-head" key={"head-"+key}>
        <div className="card-title">
          {key}
        </div>
      </div>);
        domList.push(
        <div className="yqfw" key={"fuwu-"+key}>
          {group.map((vo)=>{
            return this.renderFuWu(vo);
          })}
        </div>);


    })


    return (
      <div className="ServicesPage ">
        <TopBar activeIndex={2}/>

        {domList}
        <Footer activeIndex={2}/>

      </div>);
  }


  renderFuWu(vo)
  {
    let {name,icon,link} = vo;
    return (
      <a href={link} className="fuwu-li" key={"fuwu"+vo.id}>
        <img src={icon} alt=""/>
        <div className="fuwu-name">{name}</div>
      </a>
    )
  }

  // renderFuWu()
  // {
  //   return (
  //     <div className="fuwu-li">
  //       <img src={require("../res/0306/首页/更多服务/体育服务/tiyu_huodongjiaoliu.png")} alt=""/>
  //       <div className="fuwu-name">服务名称</div>
  //     </div>
  //   )
  // }

}

export default ServicesPage;