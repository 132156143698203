import React, {Component} from 'react';
import "./SearchBar.css";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";


export default class SearchBar extends Component
{
  static defaultProps = {
    onSearch: (keyword) => {

    }
  }
  static propTypes = {
    onSearch: PropTypes.func
  }


  constructor(props)
  {
    super(props);
    this.lastKeyword = "";
    this.keyword = "";
  }


  componentDidMount()
  {
    this.checkTfChangedItv = setInterval(() => {
      if (this.lastKeyword != this.keyword)
      {
        this.props.onSearch(this.keyword);
        this.lastKeyword = this.keyword;

      }
    }, 1000)
  }

  componentWillUnmount()
  {
    clearInterval(this.checkTfChangedItv);
  }


  render()
  {
    return (
      <div className="SearchBar">
        <img className="search-icon mobile-only" src={require("../res/0306/discover_search.png")}/>
        <img className="search-icon pc-only" src={require("../res/pc/pc_search.png")}/>
        <input className={"search-tf"} name={"search"} placeholder="搜索"
               onChange={(e) => {
                 this.keyword = e.target.value;
               }}/>
      </div>);
  }

}