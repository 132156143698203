import Facade from "./Facade";
import Notify from "./Notify";

export default class MHuoDong{




  page= 1;
  pageEnd=false;
  listItems=[];
  isLoadingLock=false;

  /**
   search：搜索关键字
   */
  search ="";

  clear( search ) {
    this.search = search;
    // 当前页码
    this.page = 1;
    this.pageEnd = false;
    this.listItems = [];
    this.isLoadingLock=false;
    Facade.sendNotify(Notify.HUODONG_LIST_CHANGED, this);
  }

  loadMore() {
    if ( this.isLoadingLock) return;
    if ( this.pageEnd ) return ;
    this.isLoadingLock=true;
    Facade.gw.getActivityList(this.search,this.page, 10, this.onlistItemsDelta.bind(this));
  }

  onlistItemsDelta(data) {
    this.isLoadingLock=false;
    this.page++;
    if (!data || data.length <= 0)
      this.pageEnd = true;
    else {
      this.listItems = this.listItems.concat(  (data));
    }
    Facade.sendNotify(Notify.HUODONG_LIST_CHANGED,this);
  }


  cacheGetById( vid )
  {
    return this.listItems.find( (li)=>{ return li.id == vid ;});
  }

  cacheModify(vid , val)
  {
    let index = this.listItems.findIndex( (li)=>{ return li.id == vid ;} );
    this.listItems[index] = val;
    Facade.sendNotify(Notify.HUODONG_LIST_CHANGED, this);
  }


  // fixListItem ( data )
  // {
  //   return data.map((li,index)=>{
  //     li.formatTime = getApp().timeUtil.formatStringTime(li.create_time);
  //     return li ;
  //   })
  // }
}